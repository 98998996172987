import React, { useContext } from 'react';
import { OutboundLink } from "gatsby-plugin-google-analytics"
import PropTypes from 'prop-types';
import NavbarWrapper from 'reusecore/src/elements/Navbar';
import Drawer from 'reusecore/src/elements/Drawer';
import Logo from 'reusecore/src/elements/UI/Logo';
import Box from 'reusecore/src/elements/Box';
import Button from 'reusecore/src/elements/Button';
import HamburgMenu from '../../../components/HamburgMenu';
import Container from '../../../components/UI/Container';
import { DrawerContext } from '../../../contexts/DrawerContext';
import { MENU_ITEMS } from '../../../data/Saas';
import { MENU_LEFT_ITEMS } from '../../../data/Saas';
import { MENU_RIGHT_ITEMS } from '../../../data/Saas';
import ScrollSpyMenu from '../../../components/ScrollSpyMenu';
import LogoImage from '../../../assets/image/saas/ma_treso_logo.png';
import { setCookie } from "../../../cookie"

const Navbar = ({ navbarStyle, logoStyle, button, row, menuWrapper, btnStyle, outlineBtnStyle }) => {
  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  return (
    <NavbarWrapper {...navbarStyle}>
      <Container noGutter mobileGutter width="1200px">
        <Box {...row}>
          <Logo
            href="#"
            logoSrc={LogoImage}
            title="Portfolio"
            logoStyle={logoStyle}
            className="logo-alt"
          />
          <Box {...menuWrapper}>
            <ScrollSpyMenu
              className="main_menu menuLeft"
              menuItems={MENU_LEFT_ITEMS}
              offset={-70}
            />
            <ScrollSpyMenu
              className="main_menu menuRight"
              menuItems={MENU_RIGHT_ITEMS}
              offset={-70}
            />
            <OutboundLink href="/app/new-onboarding">
              <Button {...btnStyle} title="SE CONNECTER" onClick={setCookie} />
            </OutboundLink>

            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#5E514D" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={MENU_ITEMS}
                drawerClose={true}
                offset={-100}
              />
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    className: 'hosting_navbar',
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  logoStyle: {
    maxWidth: ['50px', '50px'],
  },
  button: {
    type: 'button',
    fontSize: '13px',
    padding: '2px',
    minHeight: 'auto',
    color: '#5E514D',
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
  },
  btnStyle: {
    minWidth: ["120px", "120px", "120px", "156px"],
    fontSize: ["13px", "14px"],
    fontWeight: "500",
    colors: "primaryWithBg",
    mr: "18px"
  },
  outlineBtnStyle: {
    minWidth: "156px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#5E514D",
    backgroundColor: "#E5E5E5",
  },
};

export default Navbar;
